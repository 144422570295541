
import { AccountItem, GetAccountDetails } from "@/types/responses"
import { TableMode } from "@/types/enums"
const RadioSearchDb = () => import("@/components/account/RadioSearchDb.vue")
const RemoveAccountDialog = () => import("@/components/dialogs/RemoveAccountDialog.vue")
const Results = () => import("@/components/account/Results.vue")
import Vue from "vue"
export default Vue.extend({
	name: "AccountRemove",
	components: {
		RadioSearchDb,
		RemoveAccountDialog,
		Results
	},
	data(): {
		search: string
		searching: boolean
		snackBar: boolean
		snackBarText: string
		details: boolean
		removing: boolean
		requestingAccountDetailsFor: string
		tableMode: TableMode

	} {
		return {
			search: "",
			searching: true,
			details: false,
			removing: false,
			snackBar: false,
			snackBarText: "",
			requestingAccountDetailsFor: "",
			tableMode: {remove: true, view: false}
			}
	},
	computed: {
		getSelectedAccount(): AccountItem {
            return this.$store.state.context.selectedAccount
        }
	},
	methods: {

		clearSearch(): void {
			this.search = ""
		},
		itemRemoved(message: string){
			if(message === this.getSelectedAccount.userId){
				message = "Account " +"<strong>" + message + "</strong>"+ " was successfuly removed."
				this.showSnackBarMessage(message)
			}else{
				this.showSnackBarMessage(message)
			}
		},
		showSnackBarMessage(message: string){
			this.snackBarText = message
			this.snackBar = true
		},
		refresh(): void {
			this.removing = false
			this.details = false
		},
		openRemoveDialog(account: AccountItem): void{
			this.removing = true

			this.$vStore.dispatch("context/setSelectedAccount", account)

		},	
	},
})
